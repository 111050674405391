import * as Sentry from "@sentry/vue";
import { Integrations } from "@sentry/tracing";

function initSentry(app, router = null, user = null) {
  const dsn = document.querySelector("meta[name=dsn][content]")?.content;

  if (!dsn) {
    console.warn("DSN not found");
    return null;
  }

  const traceOptions = {};
  traceOptions.tracingOrigins = ['localhost', 'tourhero.com', 'staging.tourhero.com', /^\//];
  if (router) traceOptions.routingInstrumentation = Sentry.vueRouterInstrumentation(router);
  if (user) Sentry.setUser(user);

  return Sentry.init({
    app,
    dsn,
    debug: false,
    integrations: [new Integrations.BrowserTracing(traceOptions)],
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
  });
}

export { initSentry };
